<template>
  <div id="mianBigBox" class="clear openSidebar">
    <div class="left_nav">
      <Menu
        ref="Menu"
        :active-name="activePath"
        :open-names="openNames"
        accordion
      >
        <Submenu v-for="list in rolesData" :key="list.value" :name="list.value">
          <template slot="title">
            {{ list.title }}
            <!-- <Icon v-if="list.value === 'BASIC:FILEMANA'" type="icon-paper" />
            <Icon v-if="list.value === 'BASIC:SYSTEM'" type="icon-settings" /> -->
          </template>
          <MenuItem
            v-for="item in list.child"
            :name="item.path"
            :to="item.path"
            :key="item.title"
            >{{ item.title }}</MenuItem
          >
        </Submenu>
      </Menu>
    </div>
    <div class="right_con" :class="{ right_con_fold: isCollapse }">
      <div class="right_con_nav">
        <el-tabs
          v-model="activeTab"
          closable
          @tab-click="clickTab"
          @tab-remove="removeTab"
        >
          <el-tab-pane
            v-for="item in tabs"
            :key="item.fullPath"
            :label="item.title"
            :name="item.fullPath"
          >
          </el-tab-pane>
        </el-tabs>
        <!-- <Tabs v-model="activeTab" closable @on-click="clickTab" @on-tab-remove="clearTab">
          <TabPane v-for="item in tabs" :label="item.title" :name="item.fullPath" :key="item.fullPath">{{ item.title }}</TabPane>
        </Tabs> -->
        <div class="clearBt" @click="clearTab">
          <i class="el-icon-delete"></i>
        </div>
      </div>
      <div class="right_con_con">
        <router-view :key="$route.fullPath"></router-view>
        <div class="footer">
          <span
            >Copyright © 2019 - {{ thisYear }} Youthsrun. All Rights Reserved.
            有数互动 版权所有</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      activeTab: "",
      tabs: [],
      userInfo: "",
      rolesData: [],
      roleId: "", // null管理员  不为null员工

      iconList: {
        "BASIC:FILEMANA": "icon-paper",
        "BASIC:SYSTEM": "icon-settings",
      },

      thisYear: "",

      activePath: "",
      openNames: ["BASIC:FILEMANA"],
    };
  },
  computed: {
    ...mapState(["isCollapse"]),
  },
  created() {
    const thisYearDate = new Date();
    this.thisYear = thisYearDate.getFullYear();

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.rolesData = this.$store.state.rolesData;
    this.tabs = JSON.parse(sessionStorage.getItem("tabs"));
    this.activeTab = sessionStorage.getItem("active_tab");

    // 菜单默认选中
    this.setupMenu();
  },
  watch: {
    $route: {
      handler: function () {
        this.tabs = JSON.parse(sessionStorage.getItem("tabs"));
        this.activeTab = sessionStorage.getItem("active_tab");
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["setCollapse"]),
    clickTab(tab) {
      this.$router.push(tab.name);
    },
    setupMenu() {
      const val = this.$route.meta.value;
      const regex = /:(.*):/;
      const match = val.match(regex);
      if (match && match.length > 1) {
        const text = `BASIC:${match[1]}` || "FILEMANA";
        this.openNames = [text];
      }
      this.activePath = this.$route.path;
    },
    removeTab(targetName) {
      if (this.tabs.length == 1) {
        return;
      }
      this.tabs.forEach((v, i) => {
        if (v.fullPath === targetName) {
          if (targetName === this.activeTab) {
            if (i !== 0) {
              this.tabs.splice(i, 1);
              sessionStorage.setItem("active_tab", this.tabs[i - 1].fullPath);
              this.$router.push(this.tabs[i - 1].fullPath);
            } else {
              this.tabs.splice(i, 1);
              sessionStorage.setItem("active_tab", this.tabs[0].fullPath);
              this.$router.push(this.tabs[0].fullPath);
            }
          } else {
            this.tabs.splice(i, 1);
          }
          sessionStorage.setItem("tabs", JSON.stringify(this.tabs));
          return;
        }
      });
    },
    // 清除tab
    clearTab() {
      this.$confirm({
        isShow: true,
        message: "确认清空吗?",
      })
        .then(() => {
          this.tabs = [
            {
              title: this.$route.meta.tab,
              fullPath: this.$route.fullPath,
            },
          ];
          sessionStorage.setItem("tabs", JSON.stringify(this.tabs));
          sessionStorage.setItem("active_tab", this.tabs[0].fullPath);
          this.$Message.success("清空成功");
        })
        .catch(() => {
          this.$Message.warning("已取消清空");
        });
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/index.css";
</style>
